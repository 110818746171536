import { type ClassValue, clsx } from "clsx";
import { CSSProperties } from "react";
import { To, useHref } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export interface UseAppSchemeURLOptions {
  mode: "push" | "present";
}

export function useAppSchemeURL(to: To, options?: UseAppSchemeURLOptions) {
  const href = useHref(to);
  const absoluteURL = new URL(href, location.href);
  const appSchemeURL = new URL("stackie://web");
  const searchParams = appSchemeURL.searchParams;
  searchParams.set("url", absoluteURL.toString());
  searchParams.set("hidden_navigation_bar", "true");
  searchParams.set("fit_safe_area", "false");
  if (options?.mode === "present") {
    searchParams.set("need_present", "true");
  }
  return appSchemeURL.toString();
}

export function toast(content: string) {
  alert(content);
}

// 运行时设置 viewTransitionName 使用该函数， 如 <div style={defineViewId('hello')} />
// 编译时可以确定的 viewTransitionName 使用 tailwind 设置，如 <div className="view-id-[hello]" />
export function defineViewId(name: string) {
  return {
    viewTransitionName: name,
  } as CSSProperties;
}

export function waitUntil<T>(promise: Promise<T>, ms?: number): Promise<T | undefined> {
  const until = new Promise<undefined>((resolve) => {
    setTimeout(resolve, ms ?? 0);
  });
  return Promise.race([promise, until]);
}
