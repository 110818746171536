import { useMemo } from "react";

import { PossibleUserRender, useConfidenceUserContext } from "@/app/context";
import { CalendarProviderName } from "@/generated";
import { defineViewId } from "@/lib/utils";

import { HeaderLeftButton, SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../../components/settings";
import { CommonLoading } from "../components";

function CredentialsGroup() {
  const { user } = useConfidenceUserContext();
  const dolsCalendar = useMemo(() => {
    return user.accounts!.find((c) => c.provider === CalendarProviderName["self_hosted"])!;
  }, [user]);
  return (
    <SettingGroup title="Credentials">
      <SettingItem label="Account" suffix={dolsCalendar.account_name} />
      <SettingItem label="Server" suffix={`dav.heydola.com`} />
    </SettingGroup>
  );
}

export default function DolaCalendarGuide() {
  return (
    <SettingSubpage
      headerLeft={
        <HeaderLeftButton viewId="settings-calendars" to="/settings/calendars">
          Calendars
        </HeaderLeftButton>
      }
      header={<p style={defineViewId("settings-calendar-dola-guide")}>Sync to your Calendar App</p>}
    >
      <SettingGroups>
        <PossibleUserRender
          loading={
            <SettingGroup title="Credentials">
              <CommonLoading />
            </SettingGroup>
          }
          user={<CredentialsGroup />}
        />
      </SettingGroups>
    </SettingSubpage>
  );
}
