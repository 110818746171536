import { CSSProperties } from "react";

import {
  AvatarShape1,
  AvatarShape2,
  AvatarShape3,
  AvatarShape4,
  AvatarShape5,
  AvatarShape6,
  AvatarShape7,
  AvatarShape8,
  AvatarShape9,
  AvatarShape10,
  AvatarShape11,
  AvatarShape12,
} from "@/app/components/icons";
import { SharedEventAttendeeInfo } from "@/generated";

const shapes = [
  AvatarShape1,
  AvatarShape2,
  AvatarShape3,
  AvatarShape4,
  AvatarShape5,
  AvatarShape6,
  AvatarShape7,
  AvatarShape8,
  AvatarShape9,
  AvatarShape10,
  AvatarShape11,
  AvatarShape12,
];

const palette = ["#FFDA82", "#FF8080", "#C882FF", "#FFB4E5", "#C882FF", "#E03B95", "#FF6D04"];

const transitionCombinations: Array<[string, string]> = [];
for (let i = 0; i < palette.length; i++) {
  for (let j = 0; j < palette.length; j++) {
    if (i !== j) {
      transitionCombinations.push([palette[i], palette[j]]);
    }
  }
}

const nameToAppearance = (name: string) => {
  let seed = 0;
  for (let i = 0; i < name.length; i++) {
    seed += name.charCodeAt(i);
  }
  return [
    transitionCombinations[seed % transitionCombinations.length],
    shapes[seed % shapes.length],
    palette[seed % palette.length],
  ] as const;
};

function BuiltinUserIcon({ name }: { name: string }) {
  const [[start, end], Shape, shapeColor] = nameToAppearance(name);
  return (
    <div
      style={
        {
          "--gradient-start": start,
          "--gradient-end": end,
          "--shape-color": shapeColor,
        } as CSSProperties
      }
      className="flex h-6 w-6 flex-col items-center justify-center rounded-full bg-gradient-to-b from-[var(--gradient-start)] to-[var(--gradient-end)] text-white"
    >
      <Shape className="h-4 w-4 fill-[var(--shape-color)]" />
    </div>
  );
}

export default function UserAvatar({ user }: { user: SharedEventAttendeeInfo }) {
  if (user.avatar_url) {
    return (
      <div
        style={{ backgroundImage: `url(${user.avatar_url})` }}
        className="h-6 w-6 overflow-hidden rounded-full bg-cover bg-center bg-no-repeat"
      ></div>
    );
  }
  return <BuiltinUserIcon name={user.name} />;
}
