import {
  Bell,
  CalendarCheck,
  CalendarCog,
  Clock10,
  Info,
  KeyRound,
  Languages,
  LoaderCircle,
  MessageCircle,
  Scale,
  User,
  Watch,
} from "lucide-react";
import { useTranslation } from "react-i18next";

import { defineViewId } from "@/lib/utils";

import { PossibleUserRender } from "../../context";
import { ConflictCheck, LanguageLabel, Switch24Hour, TimezoneLabel } from "../components/controls";
import { SettingGroup, SettingGroups, SettingItem } from "../components/settings";

export default function SettingsHomePage() {
  const { t: text } = useTranslation("main", { keyPrefix: "Settings", useSuspense: false });
  const { t: notifyText } = useTranslation("main", { keyPrefix: "NotificationSettings", useSuspense: false });
  const { t: measureText } = useTranslation("main", { keyPrefix: "Measurement", useSuspense: false });
  return (
    <div className={"flex h-full min-h-[100dvh] w-full flex-col gap-4 py-6"}>
      <h3 className="flex flex-row items-center gap-2 px-4 text-2xl font-bold leading-tight">
        <p className="view-id-[setting-main-title]">{text("title")}</p>
      </h3>
      <SettingGroups>
        <SettingGroup>
          <PossibleUserRender
            loading={
              <div className="flex flex-row items-center justify-center py-8">
                <LoaderCircle className="animate-spin" />
              </div>
            }
            anonymous={
              <div className="flex flex-col items-center justify-center gap-4 py-8">
                <KeyRound className="w-8" />
                <div className="text-slate-400">Login Required</div>
              </div>
            }
            user={(u) => (
              <SettingItem
                icon={<User className="w-4 fill-yellow-100 stroke-yellow-600" />}
                label={u.settings.nickname}
                description="myaccount@gmail.com"
                href="/settings/account"
              />
            )}
          />
        </SettingGroup>

        <SettingGroup>
          <SettingItem
            icon={<Languages className="w-4 fill-yellow-100 stroke-yellow-600" />}
            label={<p className="view-id-[setting-language]">{text("InformationSection.language.label")}</p>}
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={(u) => <LanguageLabel user={u} />}
              />
            }
            href="/settings/language"
          />
          <SettingItem
            icon={<Clock10 className="w-4 fill-blue-100 stroke-blue-600" />}
            label={<p className="view-id-[setting-timezone]">{text("InformationSection.timeZone.label")}</p>}
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={(u) => <TimezoneLabel user={u} />}
              />
            }
            href="/settings/timezone"
          />
          <SettingItem
            icon={<Scale className="w-4 fill-amber-100 stroke-amber-700" />}
            label={<p className="view-id-[setting-units]">{text("MeasurementSection.label")}</p>}
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={(u) =>
                  u.settings.units ? (
                    <p style={defineViewId(`setting-units-${u.settings.units}`)}>{measureText(u.settings.units)}</p>
                  ) : null
                }
              />
            }
            href="/settings/units"
          />
          <SettingItem
            icon={<Watch className="w-4 fill-teal-100 stroke-teal-600" />}
            label={text("InformationSection.time_format.label")}
            suffix={<PossibleUserRender user={<Switch24Hour />} />}
          />
          <SettingItem
            icon={<CalendarCheck className="w-4 fill-lime-100 stroke-lime-600" />}
            label={notifyText("conflict.label")}
            description={notifyText("conflict.off_tip")}
            suffix={<PossibleUserRender user={<ConflictCheck />} />}
          />
        </SettingGroup>

        <SettingGroup>
          <SettingItem
            icon={<CalendarCog className="w-4 fill-violet-100 stroke-violet-700" />}
            label={<p className="view-id-[setting-calendars]">{text("CalendarsSection.syncedCalendars.label")}</p>}
            href="/settings/calendars"
          />
        </SettingGroup>

        <SettingGroup>
          <SettingItem
            icon={<Bell className="w-4 fill-pink-100 stroke-pink-700" />}
            label={<p className="view-id-[setting-notification]">{text("AlertsSection.label")}</p>}
            href="/settings/alerts"
          />
        </SettingGroup>

        <SettingGroup>
          <SettingItem
            icon={<Info className="w-4 fill-rose-100 stroke-rose-700" />}
            label={<p className="view-id-[setting-about]">{text("about.about_dola")}</p>}
            href="/settings/about"
          />
          <SettingItem
            icon={<MessageCircle className="w-4 fill-rose-100 stroke-rose-700" />}
            label={<p className="view-id-[setting-about]">Chat</p>}
            href="/chat"
          />
        </SettingGroup>
      </SettingGroups>
    </div>
  );
}
