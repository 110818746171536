export type Language = {
  locale: SupportLocale; // 是界面使用的语言
  lang: string; // 用户的个性化配置
  localLabel: string;
  englishLabel: string;
  dir: "rtl" | "ltr";
};

export enum SupportLocale {
  AF = "af",
  AR = "ar",
  CA = "ca",
  CS = "cs",
  DA = "da",
  DE = "de",
  EL = "el",
  EN = "en",
  ES = "es",
  FA = "fa",
  FI = "fi",
  FR = "fr",
  HE = "he",
  HI = "hi",
  HU = "hu",
  IT = "it",
  JA = "ja",
  KO = "ko",
  ML = "ml",
  NB = "nb",
  NL = "nl",
  PL = "pl",
  PT = "pt",
  RO = "ro",
  RU = "ru",
  SK = "sk",
  SR = "sr",
  SV = "sv",
  TA = "ta",
  TR = "tr",
  UK = "uk",
  UR = "ur",
  VI = "vi",
  ZH = "zh",
  ZH_HANT = "zh-hant",
}

export const supportedLanguages: Language[] = [
  {
    locale: SupportLocale.AF,
    localLabel: "Afrikaans",
    englishLabel: "Afrikaans",
    lang: "af",
    dir: "ltr",
  },
  {
    locale: SupportLocale.AR,
    localLabel: "العربية",
    englishLabel: "Arabic",
    lang: "ar",
    dir: "rtl",
  },
  {
    locale: SupportLocale.CA,
    localLabel: "Català",
    englishLabel: "Catalan",
    lang: "ca",
    dir: "ltr",
  },
  {
    locale: SupportLocale.CS,
    localLabel: "Čeština",
    englishLabel: "Czech",
    lang: "cs",
    dir: "ltr",
  },
  {
    locale: SupportLocale.DA,
    localLabel: "Dansk",
    englishLabel: "Danish",
    lang: "da",
    dir: "ltr",
  },
  {
    locale: SupportLocale.DE,
    localLabel: "Deutsch",
    englishLabel: "German",
    lang: "de",
    dir: "ltr",
  },
  {
    locale: SupportLocale.EL,
    localLabel: "Ελληνικά",
    englishLabel: "Greek",
    lang: "el",
    dir: "ltr",
  },
  {
    locale: SupportLocale.EN,
    localLabel: "English",
    englishLabel: "English",
    lang: "en",
    dir: "ltr",
  },
  {
    locale: SupportLocale.ES,
    localLabel: "Español",
    englishLabel: "Spanish",
    lang: "es",
    dir: "ltr",
  },
  {
    locale: SupportLocale.FA,
    localLabel: "فارسی",
    englishLabel: "Persian",
    lang: "fa",
    dir: "rtl",
  },
  {
    locale: SupportLocale.FI,
    localLabel: "Suomi",
    englishLabel: "Finnish",
    lang: "fi",
    dir: "ltr",
  },
  {
    locale: SupportLocale.FR,
    localLabel: "Français",
    englishLabel: "French",
    lang: "fr",
    dir: "ltr",
  },
  {
    locale: SupportLocale.HE,
    localLabel: "עברית",
    englishLabel: "Hebrew",
    lang: "he",
    dir: "rtl",
  },
  {
    locale: SupportLocale.HI,
    localLabel: "हिन्दी",
    englishLabel: "Hindi",
    lang: "hi",
    dir: "ltr",
  },
  {
    locale: SupportLocale.HU,
    localLabel: "Magyar",
    englishLabel: "Hungarian",
    lang: "hu",
    dir: "ltr",
  },
  {
    locale: SupportLocale.IT,
    localLabel: "Italiano",
    englishLabel: "Italian",
    lang: "it",
    dir: "ltr",
  },
  {
    locale: SupportLocale.JA,
    localLabel: "日本語",
    englishLabel: "Japanese",
    lang: "ja",
    dir: "ltr",
  },
  {
    locale: SupportLocale.KO,
    localLabel: "한국어",
    englishLabel: "Korean",
    lang: "ko",
    dir: "ltr",
  },
  {
    locale: SupportLocale.ML,
    localLabel: "മലയാളം",
    englishLabel: "Malayalam",
    lang: "ml",
    dir: "ltr",
  },
  {
    locale: SupportLocale.NB,
    localLabel: "Norsk bokmål",
    englishLabel: "Norwegian Bokmål",
    lang: "nb",
    dir: "ltr",
  },
  {
    locale: SupportLocale.NL,
    localLabel: "Nederlands",
    englishLabel: "Dutch",
    lang: "nl",
    dir: "ltr",
  },
  {
    locale: SupportLocale.PL,
    localLabel: "Polski",
    englishLabel: "Polish",
    lang: "pl",
    dir: "ltr",
  },
  {
    locale: SupportLocale.PT,
    localLabel: "Português",
    englishLabel: "Portuguese",
    lang: "pt",
    dir: "ltr",
  },
  {
    locale: SupportLocale.RO,
    localLabel: "Română",
    englishLabel: "Romanian",
    lang: "ro",
    dir: "ltr",
  },
  {
    locale: SupportLocale.RU,
    localLabel: "Русский",
    englishLabel: "Russian",
    lang: "ru",
    dir: "ltr",
  },
  {
    locale: SupportLocale.SK,
    localLabel: "Slovenčina",
    englishLabel: "Slovak",
    lang: "sk",
    dir: "ltr",
  },
  {
    locale: SupportLocale.SR,
    localLabel: "Српски",
    englishLabel: "Serbian",
    lang: "sr",
    dir: "ltr",
  },
  {
    locale: SupportLocale.SV,
    localLabel: "Svenska",
    englishLabel: "Swedish",
    lang: "sv",
    dir: "ltr",
  },
  {
    locale: SupportLocale.TA,
    localLabel: "தமிழ்",
    englishLabel: "Tamil",
    lang: "ta",
    dir: "ltr",
  },
  {
    locale: SupportLocale.TR,
    localLabel: "Türkçe",
    englishLabel: "Turkish",
    lang: "tr",
    dir: "ltr",
  },
  {
    locale: SupportLocale.UK,
    localLabel: "Українська",
    englishLabel: "Ukrainian",
    lang: "uk",
    dir: "ltr",
  },
  {
    locale: SupportLocale.UR,
    localLabel: "اردو",
    englishLabel: "Urdu",
    lang: "ur",
    dir: "rtl",
  },
  {
    locale: SupportLocale.VI,
    localLabel: "Tiếng Việt",
    englishLabel: "Vietnamese",
    lang: "vi",
    dir: "ltr",
  },
  {
    locale: SupportLocale.ZH,
    localLabel: "中文",
    englishLabel: "Chinese",
    lang: "zh-Hans",
    dir: "ltr",
  },
  {
    locale: SupportLocale.ZH_HANT,
    localLabel: "繁體中文",
    englishLabel: "Chinese (Traditional)",
    lang: "zh-Hant",
    dir: "ltr",
  },
];

export const defaultLocale = SupportLocale.EN;
export const defaultLanguage = supportedLanguages.find((i) => i.locale === defaultLocale) as Language;

export const matchedLanguage = (locale: string, languageList: Language[] = supportedLanguages) => {
  const segments = locale.toLowerCase().split(/[-_]/g);
  while (segments.length) {
    const partialLocale = segments.join("-");
    const match = languageList.find((language) => language.locale.toLowerCase() === partialLocale);
    if (match) {
      return match;
    }
    segments.pop();
  }
  return null;
};

export const matchedLanguageMultiple = (locales: string[]) => {
  for (const locale of locales) {
    const match = matchedLanguage(locale);
    if (match) {
      return match;
    }
  }
  return null;
};
