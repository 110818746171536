import { useDocumentVisibility } from "ahooks";
import { BookOpenText, LoaderCircle, TriangleAlert } from "lucide-react";
import { PropsWithChildren, createElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { AppleCalendarIcon, DolaIcon, GoogleCalendarIcon } from "@/app/components/icons";
import { PossibleUserRender, useConfidenceUserContext } from "@/app/context";
import {
  CalendarProviderName,
  bindGoogleAccountUserAccountsGoogleGet,
  setDefaultCalendarUserCalendarsDefaultPut,
} from "@/generated";
import { defineViewId, toast } from "@/lib/utils";

import { SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";
import { ActiveTag, CommonLoading } from "./components";

function ConnectedTag({ children }: PropsWithChildren) {
  return <p className="">{children}</p>;
}

function GoToConnectTag({ viewId }: { viewId?: string }) {
  return <p style={viewId ? defineViewId(viewId) : {}}>Connect</p>;
}

function ActivateTag() {
  return <p>Activate</p>;
}

function DolaActive() {
  return (
    <SettingGroup title="Current Active">
      <div className="flex flex-col items-center justify-center gap-2 border-b border-slate-200 p-4 text-center">
        <DolaIcon className="w-16" />
        <div className="dola-gradient-text text-md px-8">
          Developed by the Dola AI team, continuously integrating the latest features.
        </div>
      </div>
      <SettingItem
        icon={<BookOpenText width={16} className="" />}
        label={<p style={defineViewId("settings-calendar-dola-guide")}>Sync to your Calendar App</p>}
        href="/settings/calendars/guide"
      />
    </SettingGroup>
  );
}

function ThirdPartyActive({ provider }: { provider: CalendarProviderName }) {
  const { t: text } = useTranslation("main", { keyPrefix: "CalendarProviderName" });
  return (
    <SettingGroup title="Current Active">
      <div className="flex flex-col items-center justify-center gap-4 p-4 text-center">
        {createElement(provider === CalendarProviderName["icloud"] ? AppleCalendarIcon : GoogleCalendarIcon, {
          className: "w-16",
        })}
        <div className="text-md px-8">{text(provider)}</div>
      </div>
    </SettingGroup>
  );
}

function CurrentActive() {
  const { user } = useConfidenceUserContext();
  const isDola = user.current_account === CalendarProviderName["self_hosted"];
  return isDola ? <DolaActive /> : <ThirdPartyActive provider={user.current_account} />;
}

function DolaCalendarItemWithActiveAction() {
  const [activating, setActivating] = useState(false);
  const { refresh: refreshUser } = useConfidenceUserContext();
  const onClick = useCallback(async () => {
    if (window.confirm("Activate Dola Calendar?")) {
      try {
        setActivating(true);
        await setDefaultCalendarUserCalendarsDefaultPut({
          provider: CalendarProviderName["self_hosted"],
        });
        toast("Activated");
        refreshUser();
      } catch (e) {
        //
      } finally {
        setActivating(false);
      }
    }
  }, [refreshUser]);
  return (
    <SettingItem
      onClick={onClick}
      icon={<DolaIcon className="w-6" />}
      label={"Dola"}
      description={<p className="dola-gradient-text">Built-in, fast and easy to use</p>}
      suffix={
        activating ? (
          <div className="flex flex-row items-center gap-1">
            <LoaderCircle height={16} className="animate-spin stroke-slate-400" />
            <span className="text-xs text-slate-400">Activating</span>
          </div>
        ) : (
          <ActivateTag />
        )
      }
    />
  );
}

function DolaCalendarItem() {
  const { user } = useConfidenceUserContext();
  const isActive = user.current_account === CalendarProviderName["self_hosted"];
  if (isActive) {
    return <SettingItem icon={<DolaIcon className="w-6" />} label={"Dola"} suffix={<ActiveTag />} />;
  }
  return <DolaCalendarItemWithActiveAction />;
}

function AppleCalendarItem() {
  const { user } = useConfidenceUserContext();
  const icloudAccount = useMemo(() => {
    if (!user.accounts) return null;
    return user.accounts.find((a) => a.provider === CalendarProviderName["icloud"]) ?? null;
  }, [user.accounts]);

  if (icloudAccount) {
    return (
      <SettingItem
        icon={<AppleCalendarIcon className="w-6" />}
        label={<p style={defineViewId(`setting-calendar-${icloudAccount.provider}`)}>Apple Calendar</p>}
        description={<ConnectedTag>{icloudAccount.account_name}</ConnectedTag>}
        href={`/settings/calendars/switch/${CalendarProviderName["icloud"]}`}
        suffix={user.current_account === CalendarProviderName["icloud"] ? <ActiveTag /> : <ActivateTag />}
      />
    );
  }

  return (
    <SettingItem
      icon={<AppleCalendarIcon className="w-6" />}
      label="Apple Calendar"
      href="/settings/calendars/connect/apple-calendar"
      suffix={<GoToConnectTag viewId="settings-calendar-apple-guide" />}
    />
  );
}

function GoogleCalendarItemWithConnect() {
  const documentVisibility = useDocumentVisibility();
  const refreshOnNextVisibleRef = useRef(false);
  const [generating, setGenerating] = useState(true);
  const [connectUrl, setConnectUrl] = useState("");
  const generateConnectUrl = useCallback(async () => {
    try {
      setGenerating(true);
      const { url } = await bindGoogleAccountUserAccountsGoogleGet();
      refreshOnNextVisibleRef.current = true;
      setConnectUrl(new URL(url).toString());
    } catch (e) {
      //
    } finally {
      setGenerating(false);
    }
  }, []);

  useEffect(() => {
    generateConnectUrl();
  }, [generateConnectUrl]);

  // TODO to be fix
  const refreshAccountCalendars = useCallback(() => {
    //
  }, []);
  const refreshAccountCalendarsRef = useRef(refreshAccountCalendars);

  useEffect(() => {
    if (refreshOnNextVisibleRef.current && documentVisibility === "visible") {
      refreshAccountCalendarsRef.current();
    }
  }, [documentVisibility]);

  if (generating) {
    return (
      <SettingItem
        icon={<GoogleCalendarIcon className="w-6" />}
        label="Google Calendar"
        suffix={<LoaderCircle width={16} className="animate-spin stroke-slate-400" />}
      />
    );
  }

  if (!connectUrl) {
    return (
      <SettingItem
        onClick={generateConnectUrl}
        icon={<GoogleCalendarIcon className="w-6" />}
        label="Google Calendar"
        suffix={
          <div className="flex flex-row items-center gap-1">
            <TriangleAlert width={16} className="stroke-red-500" />
            <span className="text-xs text-red-500">Error</span>
          </div>
        }
      />
    );
  }

  return (
    <SettingItem
      icon={<GoogleCalendarIcon className="w-6" />}
      label="Google Calendar"
      href={connectUrl}
      external
      suffix={<GoToConnectTag />}
    />
  );
}

function GoogleCalendarItem() {
  const { user } = useConfidenceUserContext();
  const googleAccount = useMemo(() => {
    if (!user.accounts) return null;
    return user.accounts.find((a) => a.provider === CalendarProviderName["google"]) ?? null;
  }, [user.accounts]);

  if (googleAccount) {
    return (
      <SettingItem
        icon={<GoogleCalendarIcon className="w-6" />}
        label=<p style={defineViewId(`setting-calendar-${googleAccount.provider}`)}>Google Calendar</p>
        description={<ConnectedTag>{googleAccount.account_name}</ConnectedTag>}
        href={`/settings/calendars/switch/${CalendarProviderName["google"]}`}
        suffix={user.current_account === CalendarProviderName["google"] ? <ActiveTag /> : <ActivateTag />}
      />
    );
  }
  return <GoogleCalendarItemWithConnect />;
}

export default function CalendarPage() {
  return (
    <SettingSubpage header={<p className="font-semibold view-id-[settings-calendars]">Calendars</p>}>
      <SettingGroups>
        <PossibleUserRender
          loading={
            <SettingGroup title="Current Active">
              <CommonLoading />
            </SettingGroup>
          }
          user={<CurrentActive />}
        />
        <SettingGroup title="All Calendar Accounts" suffix={<div></div>}>
          <PossibleUserRender
            loading={<CommonLoading />}
            user={
              <>
                <DolaCalendarItem />
                <AppleCalendarItem />
                <GoogleCalendarItem />
              </>
            }
          />
        </SettingGroup>
      </SettingGroups>
    </SettingSubpage>
  );
}
