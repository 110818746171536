import { Outlet } from "react-router-dom";

import { PossibleUserContextProvider } from "./context";

export default function SettingsLayout() {
  return (
    <PossibleUserContextProvider>
      <Outlet />
    </PossibleUserContextProvider>
  );
}
