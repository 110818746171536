import { useCallback, useEffect, useMemo, useState } from "react";

import { ClockFormat, UserInfoResponse, useUpdateUserSettingsUserSettingsPut } from "@/generated";
import { matchedLanguage } from "@/lib/i18n";
import { defineViewId, toast } from "@/lib/utils";

import { useConfidenceUserContext } from "../../context";
import { SwitchOn } from "./settings";

export function Switch24Hour() {
  const { user, setUser } = useConfidenceUserContext();
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const is24Hour = useMemo(() => {
    // 默认是 24 小时制（包括为空时）
    return user.settings.clock_format !== ClockFormat["12h"];
  }, [user]);

  const onChange = useCallback(
    async (next: boolean) => {
      const settings = await mutateAsync({ data: { clock_format: next ? ClockFormat["24h"] : ClockFormat["12h"] } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync],
  );

  return <SwitchOn loading={isPending} checked={is24Hour} onChange={onChange} />;
}

export function ConflictCheck() {
  const { user, setUser } = useConfidenceUserContext();
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const onChange = useCallback(
    async (next: boolean) => {
      const settings = await mutateAsync({ data: { calendar_conflict_checker_enabled: next } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync],
  );
  return (
    <SwitchOn loading={isPending} checked={!!user.settings.calendar_conflict_checker_enabled} onChange={onChange} />
  );
}

export function NameEnable() {
  const { user, setUser } = useConfidenceUserContext();
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const onChange = useCallback(
    async (next: boolean) => {
      const settings = await mutateAsync({ data: { nickname_enabled: next } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync],
  );
  return <SwitchOn loading={isPending} checked={!!user.settings.nickname_enabled} onChange={onChange} />;
}

export function NameInput() {
  const { user, setUser } = useConfidenceUserContext();
  const [nickname, setNickname] = useState(user.settings.nickname ?? "");
  useEffect(() => {
    setNickname(user.settings.nickname ?? "");
  }, [user.settings.nickname]);
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const onChange = useCallback(async () => {
    const settings = await mutateAsync({ data: { nickname } });
    setUser((p) => ({ ...p, settings }));
    toast("Updated");
  }, [setUser, mutateAsync, nickname]);

  return (
    <form onSubmit={onChange} className="flex flex-row">
      <input
        disabled={isPending}
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
        className="w-full bg-transparent text-right text-sm outline-none disabled:cursor-not-allowed disabled:opacity-30"
      />
    </form>
  );
}

export function LanguageLabel({ user }: { user: UserInfoResponse }) {
  const langDefine = useMemo(() => {
    if (!user.settings.language) return null;
    return matchedLanguage(user.settings.language);
  }, [user.settings.language]);
  return (
    <div style={langDefine ? defineViewId(`setting-language-${langDefine.locale}`) : {}}>
      {langDefine ? langDefine.localLabel : (user.settings.language ?? "Unknown")}
    </div>
  );
}

// TODO to be fix
export function TimezoneLabel({ user }: { user: UserInfoResponse }) {
  const langDefine = useMemo(() => {
    if (!user.settings.language) return null;
    return matchedLanguage(user.settings.language);
  }, [user.settings.language]);
  return (
    <div style={langDefine ? defineViewId(`setting-timezone-${langDefine.locale}`) : {}}>
      {langDefine ? langDefine.localLabel : (user.settings.language ?? "Unknown")}
    </div>
  );
}

// TODO 其实不应该读这个变量。务必新增一个 flag
export function NotificationSwitch() {
  const { user, setUser } = useConfidenceUserContext();
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const onChange = useCallback(
    async (next: boolean) => {
      const settings = await mutateAsync({ data: { im_notification_enabled: next } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync],
  );
  return <SwitchOn loading={isPending} checked={!!user.settings.im_notification_enabled} onChange={onChange} />;
}

export function DailyMessageSwitch() {
  const { user, setUser } = useConfidenceUserContext();
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const onChange = useCallback(
    async (next: boolean) => {
      const settings = await mutateAsync({ data: { daily_notification_enabled: next } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync],
  );
  return <SwitchOn loading={isPending} checked={!!user.settings.daily_notification_enabled} onChange={onChange} />;
}

export function RecallNotificationSwitch() {
  const { user, setUser } = useConfidenceUserContext();
  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const onChange = useCallback(
    async (next: boolean) => {
      const settings = await mutateAsync({ data: { recall_notification_enabled: next } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync],
  );
  return <SwitchOn loading={isPending} checked={!!user.settings.recall_notification_enabled} onChange={onChange} />;
}
