import { CalendarArrowDown, Cookie, HeartHandshake, LogOut, UserCheck, UserPen } from "lucide-react";

import { PossibleUserRender } from "../../context";
import { NameEnable, NameInput } from "../components/controls";
import { SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";

export default function AccountPage() {
  return (
    <SettingSubpage header={<p className="font-semibold">Account</p>}>
      <SettingGroups>
        <SettingGroup>
          <SettingItem
            description="Dola will address you by your name"
            icon={<UserPen className="w-4 fill-yellow-100 stroke-yellow-600" />}
            label="Name"
            suffix={<PossibleUserRender user={<NameInput />} />}
          />
          <SettingItem
            icon={<UserCheck className="w-4 fill-blue-100 stroke-blue-600" />}
            label="Name enable"
            suffix={<PossibleUserRender user={<NameEnable />} />}
          />
          <SettingItem icon={<CalendarArrowDown className="w-4 fill-red-100 stroke-red-600" />} label="Export Data" />
          <SettingItem icon={<LogOut className="w-4 fill-red-100 stroke-red-600" />} label="Logout" />
        </SettingGroup>

        <SettingGroup>
          <SettingItem
            icon={<Cookie className="w-4 fill-yellow-100 stroke-yellow-600" />}
            label="Privacy Policy"
            href="https://heydola.com/privacy"
            external
          />
          <SettingItem
            icon={<HeartHandshake className="w-4 fill-red-100 stroke-red-600" />}
            label="Terms of Service"
            href="https://heydola.com/tos"
            external
          />
        </SettingGroup>

        <SettingGroup>
          <SettingItem label={<p className="text-red-600">Delete Account</p>} />
        </SettingGroup>
      </SettingGroups>
    </SettingSubpage>
  );
}
