import { Bell, CalendarHeart, Clock10, LoaderCircle, PhoneCall } from "lucide-react";

import { DolaIcon } from "@/app/components/icons";

import { PossibleUserRender } from "../../context";
import { DailyMessageSwitch, NotificationSwitch, RecallNotificationSwitch } from "../components/controls";
import { SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";

export default function AlertsPage() {
  return (
    <SettingSubpage header={<p className="font-semibold view-id-[setting-notification]">Notifications</p>}>
      <SettingGroups>
        <SettingGroup title="Event Notify Methods">
          <SettingItem
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={<NotificationSwitch />}
              />
            }
            icon={<DolaIcon className="w-5" />}
            label="In-App"
          />
          <SettingItem
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={<NotificationSwitch />}
              />
            }
            icon={<PhoneCall className="w-4 fill-pink-100 stroke-pink-700" />}
            label="Phone Call"
          />
        </SettingGroup>

        <SettingGroup
          title="Daily Messages"
          suffix={"With this enabled, Dola will send you your daily schedule every day"}
        >
          <SettingItem
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={<DailyMessageSwitch />}
              />
            }
            icon={<CalendarHeart className="w-4 fill-pink-100 stroke-pink-700" />}
            label="Enable"
          />
          <SettingItem
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={<div>Same day at 12:20</div>}
              />
            }
            icon={<Clock10 className="w-4 fill-pink-100 stroke-pink-700" />}
            label="Notify At"
          />
        </SettingGroup>

        <SettingGroup suffix="When turned off, Dola will no longer send you all other notifications">
          <SettingItem
            suffix={
              <PossibleUserRender
                loading={<LoaderCircle width={16} height={16} className="animate-spin stroke-slate-300" />}
                user={<RecallNotificationSwitch />}
              />
            }
            icon={<Bell className="w-4 fill-pink-100 stroke-pink-700" />}
            label="Other Notifications"
          />
        </SettingGroup>
      </SettingGroups>
    </SettingSubpage>
  );
}
