import { defineViewId } from "@/lib/utils";

import { HeaderLeftButton, SettingGroup, SettingGroups, SettingSubpage } from "../../../components/settings";

export default function DolaCalendarGuide() {
  return (
    <SettingSubpage
      headerLeft={
        <HeaderLeftButton viewId="settings-calendars" to="/settings/calendars">
          Calendars
        </HeaderLeftButton>
      }
      header={<p style={defineViewId("settings-calendar-apple-guide")}>Connect to Apple Calendar</p>}
    >
      <SettingGroups>
        <SettingGroup>hello</SettingGroup>
      </SettingGroups>
    </SettingSubpage>
  );
}
