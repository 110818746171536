import { useRequest, useThrottleFn } from "ahooks";
import { List, LoaderCircle, Pencil, Share } from "lucide-react";
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Banner1 from "@/assets/banner-01.png";
import Banner2 from "@/assets/banner-02.png";
import Banner3 from "@/assets/banner-03.png";
import Banner4 from "@/assets/banner-04.png";
import Banner5 from "@/assets/banner-05.png";
import Banner6 from "@/assets/banner-06.png";
import Banner7 from "@/assets/banner-07.png";
import Banner8 from "@/assets/banner-08.png";
import Banner9 from "@/assets/banner-09.png";
import { getUserCalendarEventUserCalendarEventsEventUuidGet } from "@/generated";

import EventContent from "./event";

const banners = [Banner1, Banner2, Banner3, Banner4, Banner5, Banner6, Banner7, Banner8, Banner9];

export default function EventDetailPage() {
  const bannerRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLElement | null>(null);
  const { uuid } = useParams() as { uuid: string };
  const { data, loading } = useRequest(() => getUserCalendarEventUserCalendarEventsEventUuidGet({ eventUuid: uuid }), {
    refreshDeps: [uuid],
    refreshOnWindowFocus: true,
  });

  const bannerImage = useMemo(() => {
    const index = uuid.split("").reduce((acc, cur) => acc + cur.charCodeAt(0), 0);
    return banners[index % banners.length];
  }, [uuid]);

  const [headerOpacity, setHeaderOpacity] = useState(0);
  const { run: checkOpacity } = useThrottleFn(
    () => {
      const banner = bannerRef.current;
      const header = headerRef.current;
      if (!banner || !header) return;
      const threaholdHeight = banner.getBoundingClientRect().height - header.getBoundingClientRect().height;
      const scrollDistance = document.documentElement.scrollTop;
      if (scrollDistance >= threaholdHeight) {
        setHeaderOpacity(1);
      } else {
        setHeaderOpacity(scrollDistance / threaholdHeight);
      }
    },
    { wait: 60 },
  );

  useEffect(() => {
    document.addEventListener("scroll", checkOpacity);
    return () => {
      document.removeEventListener("scroll", checkOpacity);
    };
  }, [checkOpacity]);

  return (
    <div
      style={
        {
          "--header-height": "42px",
          "--banner-height": "180px",
        } as CSSProperties
      }
      className="min-h-[100dvh] w-full bg-white"
    >
      <header
        ref={headerRef}
        style={{ "--tw-bg-opacity": headerOpacity } as CSSProperties}
        className="fixed top-0 flex h-[var(--header-height)] w-full flex-row items-center justify-between gap-4 bg-white bg-opacity-0 px-4 transition-all"
      >
        <Link to="/events">
          <List width={16} />
        </Link>
        <div className="flex-1"></div>
        <button>
          <Share width={16} />
        </button>
        <button>
          <Pencil width={16} />
        </button>
      </header>
      <div
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
        ref={bannerRef}
        className="h-[var(--banner-height)] w-full bg-slate-100 bg-cover bg-center bg-no-repeat"
      ></div>
      <div
        style={{ background: `linear-gradient(98deg, #FFB404 1.14%, #F00 44.14%, #8908EE 87.15%)` }}
        className="sticky top-[var(--header-height)] h-1 w-full bg-red-600"
      ></div>
      {data ? (
        <EventContent event={data} />
      ) : loading ? (
        <div className="flex w-full flex-row items-center justify-center py-10">
          <LoaderCircle className="w-16 animate-spin stroke-slate-300" />
        </div>
      ) : null}
    </div>
  );
}
