import { PropsWithChildren, createElement, useMemo } from "react";

import { MultiFriend, NotePaper, PinPoint, RingBell } from "@/app/components/icons";
import { ListUserCalendarEventsResponseEvent } from "@/generated";

import UserAvatar from "./avatar";

function InformationRow({ icon, children }: PropsWithChildren<{ icon?: typeof RingBell }>) {
  return (
    <div className="flex flex-row items-start gap-3">
      <div className="flex h-[16px] w-4 flex-col items-center justify-center">
        {icon ? createElement(icon, { className: "fill-[#666] w-4" }) : null}
      </div>
      <div className="flex-1 py-[2px] text-[12px] leading-[12px] text-[#666]">{children}</div>
    </div>
  );
}

function TextInformationSection({ event }: { event: ListUserCalendarEventsResponseEvent }) {
  if (!event.location && !event.notes) return null;
  return (
    <div className="flex flex-col gap-6 border-b border-slate-200 py-6">
      {event.location ? <InformationRow icon={PinPoint}>{event.location}</InformationRow> : null}
      {event.notes ? <InformationRow icon={NotePaper}>{event.notes}</InformationRow> : null}
    </div>
  );
}

function FollowerInformationSection({ event }: { event: ListUserCalendarEventsResponseEvent }) {
  const members = useMemo(() => {
    if (!event.share) return [];
    const a = event.share.organizer;
    const m = event.share.attendees ?? [];
    if (!m.length) return [];
    return [a, ...m];
  }, [event.share]);
  return (
    <div className="py-6">
      <InformationRow icon={MultiFriend}>Followers:</InformationRow>
      <InformationRow>
        <div className="flex flex-row flex-wrap gap-x-6 gap-y-2 pt-2">
          {members.map((member, idx) => (
            <div key={idx} className="flex flex-row items-center gap-2">
              <UserAvatar user={member} />
              <span>{member.name}</span>
            </div>
          ))}
        </div>
      </InformationRow>
    </div>
  );
}

export default function EventContent({ event }: { event: ListUserCalendarEventsResponseEvent }) {
  return (
    <div className="bg-white px-4 py-6">
      <div className="flex flex-col gap-4 border-b border-slate-200 pb-6">
        <h2 className="text-[32px] leading-tight">
          {event.emoji ? `${event.emoji} ` : ""}
          {event.title}
        </h2>
        <div>{event.recurrence_rules_text}</div>
        <InformationRow icon={RingBell}>{event.reminders_text}</InformationRow>
        <div>CALENDAR</div>
      </div>
      <TextInformationSection event={event} />
      <FollowerInformationSection event={event} />
      <div className="mt-8 flex flex-row items-center justify-center">
        <button onClick={() => alert("Call Jsbridge")} className="text-xs underline">
          Delete
        </button>
      </div>
    </div>
  );
}
