import * as Sentry from "@sentry/react";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { broadcastQueryClient } from "@tanstack/query-broadcast-client-experimental";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import i18n from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import localforage from "localforage";
import React from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import {
  RouterProvider,
  createHashRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import Home from "./app/(home)/page.tsx";
import ChatPage from "./app/chat/page.tsx";
import PersistGate from "./app/components/persist-gate.tsx";
import EventList from "./app/events/(list)/page.tsx";
import EventDetailPage from "./app/events/[uuid]/page.tsx";
import EventsLayout from "./app/events/layout.tsx";
import RootLayout from "./app/layout.tsx";
import SettingsHomePage from "./app/settings/(home)/page.tsx";
import About from "./app/settings/about/page.tsx";
import AccountPage from "./app/settings/account/page.tsx";
import AlertsPage from "./app/settings/alerts/page.tsx";
import ConnectAppleCalendar from "./app/settings/calendars/connect/apple-calendar/page.tsx";
import DolaCalendarGuide from "./app/settings/calendars/guide/page.tsx";
import CalendarsPage from "./app/settings/calendars/page.tsx";
import SwitchThirdPartyCalendar from "./app/settings/calendars/switch/[provider]/page.tsx";
import SettingsLanguagePage from "./app/settings/language/page.tsx";
import SettingsLayout from "./app/settings/layout.tsx";
import SettingsTimezonePage from "./app/settings/timezone/page.tsx";
import SettingUnitsPage from "./app/settings/units/page.tsx";
import "./index.css";
import AccessTokenProvider from "./jsbridge/access-token-provider.tsx";

dayjs.extend(LocalizedFormat);

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://7f7c2fa4bfd576d7534fe226543bd6d8@o4505185715748864.ingest.us.sentry.io/4507768171331584",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

const sentryCreateHashRouter = import.meta.env.PROD
  ? Sentry.wrapCreateBrowserRouter(createHashRouter)
  : createHashRouter;

const router = sentryCreateHashRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "chat",
        element: <ChatPage />,
      },
      {
        path: "events",
        element: <EventsLayout />,
        children: [
          {
            path: "",
            element: <EventList />,
          },
          {
            path: ":uuid",
            element: <EventDetailPage />,
          },
        ],
      },
      {
        path: "settings",
        element: <SettingsLayout />,
        children: [
          {
            path: "",
            element: <SettingsHomePage />,
          },
          {
            path: "account",
            element: <AccountPage />,
          },
          {
            path: "language",
            element: <SettingsLanguagePage />,
          },
          {
            path: "timezone",
            element: <SettingsTimezonePage />,
          },
          {
            path: "units",
            element: <SettingUnitsPage />,
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "alerts",
            element: <AlertsPage />,
          },
          {
            path: "calendars",
            element: <CalendarsPage />,
          },
          {
            path: "calendars/guide",
            element: <DolaCalendarGuide />,
          },
          {
            path: "calendars/switch/:provider",
            element: <SwitchThirdPartyCalendar />,
          },
          {
            path: "calendars/connect/apple-calendar",
            element: <ConnectAppleCalendar />,
          },
        ],
      },
    ],
  },
]);

const cacheStore = localforage.createInstance({
  name: "react-query-cache",
});

const persister = createAsyncStoragePersister({
  storage: cacheStore,
});

// increase the number when the data structure changes
// to burst the cache
const buster = "1";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      gcTime: Infinity,
    },
  },
});

broadcastQueryClient({ queryClient });

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(resourcesToBackend((language: string, namespace: string) => import(`./messages/${namespace}/${language}.json`)))
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister, buster }}>
      <PersistGate>
        <AccessTokenProvider>
          <RouterProvider router={router} />
        </AccessTokenProvider>
      </PersistGate>
    </PersistQueryClientProvider>
  </React.StrictMode>,
);
