import { Check, LoaderCircle } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Units, useUpdateUserSettingsUserSettingsPut } from "@/generated";
import { defineViewId } from "@/lib/utils";

import { PossibleUserRender, useConfidenceUserContext } from "../../context";
import { SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";

function UnitPicker() {
  const { t: measureText } = useTranslation("main", { keyPrefix: "Measurement", useSuspense: false });
  const { setUser, user } = useConfidenceUserContext();

  const options = useMemo(() => {
    return [Units["metric"], Units["imperial"]].map((v) => ({
      value: v,
      label: measureText(v),
    }));
  }, [measureText]);

  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const [pendingValue, setPendingValue] = useState<string>();
  // Handle Cancel
  const onChange = useCallback(
    async (next: Units) => {
      if (isPending) return;
      setPendingValue(next);
      const settings = await mutateAsync({ data: { units: next } });
      setUser((p) => ({ ...p, settings }));
      setPendingValue(undefined);
    },
    [setUser, mutateAsync, isPending],
  );

  return (
    <SettingGroup>
      {options.map(({ label, value }) => (
        <SettingItem
          key={value}
          onClick={() => onChange(value)}
          label={
            <p className="h-6" style={defineViewId(`setting-units-${value}`)}>
              {label}
            </p>
          }
          suffix={
            pendingValue === value ? (
              <LoaderCircle className="w-4 animate-spin stroke-slate-400" />
            ) : user.settings.units === value ? (
              <Check className="w-5 stroke-green-600" />
            ) : null
          }
        />
      ))}
    </SettingGroup>
  );
}

export default function LanguageSettingPage() {
  const { t: text } = useTranslation("main", { keyPrefix: "Settings", useSuspense: false });
  return (
    <SettingSubpage
      header={<p className="font-semibold view-id-[setting-units]">{text("MeasurementSection.label")}</p>}
    >
      <SettingGroups>
        <PossibleUserRender
          loading={
            <SettingGroup>
              <div className="flex flex-col items-center justify-center py-10">
                <LoaderCircle className="w-20 animate-spin stroke-slate-400" />
              </div>
            </SettingGroup>
          }
          user={<UnitPicker />}
        />
      </SettingGroups>
    </SettingSubpage>
  );
}
