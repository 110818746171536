import { CalendarPlus2, Check, CircleDot, CircleDotDashed, Link2Off, LoaderCircle, TriangleAlert } from "lucide-react";
import {
  ButtonHTMLAttributes,
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  createElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { AppleCalendarIcon, DolaIcon, GoogleCalendarIcon } from "@/app/components/icons";
import { PossibleUserRender, useConfidenceUserContext } from "@/app/context";
import {
  CalendarAccountInfo,
  CalendarInfoResponse,
  CalendarProviderName,
  listCalendarsUserCalendarsGet,
  setDefaultCalendarV2UserV2CalendarsDefaultPut,
  unbindCalendarAccountUserAccountsProviderDelete,
} from "@/generated";
import { cn, defineViewId, toast } from "@/lib/utils";

import {
  HeaderLeftButton,
  SettingGroup,
  SettingGroups,
  SettingItem,
  SettingSubpage,
  SwitchOn,
} from "../../../components/settings";
import { CommonLoading } from "../../components";

type PageContextType = {
  calendarAccount: CalendarAccountInfo;
  calendars: CalendarInfoResponse[];
  calendarMapping: Record<string, CalendarInfoResponse>;
  loadingCalendars: boolean;
  defaultId: string | undefined;
  setDefaultId: Dispatch<string>;
  isEditMode: boolean;
  setIsEditMode: Dispatch<boolean>;
  selected: string[];
  toggleSelectId: Dispatch<string>;
  migrate: boolean;
  setMigrate: Dispatch<SetStateAction<boolean>>;
  activateEnable: boolean;
  activating: boolean;
  performActivate: () => Promise<void>;
  isAlreadyActive: boolean;
};
const PageContext = createContext({} as PageContextType);

function usePageContext() {
  return useContext(PageContext);
}

const MAX_ENABLED = 3;
function PageContextProvider({ children, provider }: PropsWithChildren<{ provider: CalendarProviderName }>) {
  const [isEditMode, localSetIsEditMode] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [migrate, setMigrate] = useState(true);
  const [defaultId, localSetDefaultId] = useState<string>();
  const { user, refresh: refreshUser } = useConfidenceUserContext();
  const calendarAccount = useMemo(() => {
    // user.accounts is always exist
    return user.accounts!.find((c) => c.provider === provider)!;
  }, [user, provider]);

  const controllerRef = useRef<AbortController | null>(null);
  const [loadingCalendars, setLoadingCalendars] = useState(true);
  const [allCalendars, setAllCalendars] = useState<CalendarInfoResponse[]>([]);
  const performLoadCalendars = useCallback(async () => {
    if (controllerRef.current) controllerRef.current.abort();
    controllerRef.current = new AbortController();
    try {
      setLoadingCalendars(true);
      setAllCalendars(await listCalendarsUserCalendarsGet(controllerRef.current.signal));
      setLoadingCalendars(false);
    } catch (e: any) {
      setAllCalendars([]);
      if (e && e.name === "CanceledError") {
        //
      } else {
        setLoadingCalendars(false);
      }
    }
  }, []);

  useEffect(() => {
    performLoadCalendars();
    return () => {
      controllerRef.current?.abort();
    };
  }, [performLoadCalendars]);

  const calendars = useMemo(() => {
    const providerCalendars = allCalendars.filter((c) => c.provider === calendarAccount.provider);
    const enabled: CalendarInfoResponse[] = [];
    const disabled: CalendarInfoResponse[] = [];
    providerCalendars.forEach((cal) => {
      if (cal.is_default) {
        enabled.unshift(cal);
      } else if (cal.is_enabled) {
        enabled.push(cal);
      } else {
        disabled.push(cal);
      }
    });
    return [...enabled, ...disabled];
  }, [allCalendars, calendarAccount]);

  const calendarMapping = useMemo(
    () =>
      calendars.reduce(
        (accu, cur) => {
          accu[cur.id] = cur;
          return accu;
        },
        {} as Record<string, CalendarInfoResponse>,
      ),
    [calendars],
  );

  useEffect(() => {
    // We want make sure `defaultId` is alway one of `selected`
    localSetDefaultId((prevDefault) => {
      if (!selected.length) return undefined;
      if (typeof prevDefault === "undefined" || !selected.includes(prevDefault)) {
        return selected[0];
      }
      return prevDefault;
    });
  }, [selected]);

  const toggleSelectId = useCallback((calId: string) => {
    let isOverLimit = false;
    setSelected((prev) => {
      if (prev.includes(calId)) {
        return prev.filter((c) => c !== calId);
      }
      if (prev.length >= MAX_ENABLED) {
        isOverLimit = true;
        return prev;
      } else {
        return [...prev, calId];
      }
    });
    if (isOverLimit) {
      toast(`You can only enable up to ${MAX_ENABLED} calendars`);
    }
  }, []);

  const setDefaultId = useCallback(
    (calId: string) => {
      if (selected.includes(calId)) {
        localSetDefaultId(calId);
      } else {
        localSetDefaultId(selected[0]);
      }
    },
    [selected],
  );

  const setIsEditMode = useCallback(
    (edit: boolean) => {
      if (edit) {
        localSetIsEditMode(true);
        setSelected(calendars.filter((c) => c.is_enabled).map((c) => c.id));
        localSetDefaultId(calendars.find((c) => c.is_default)?.id);
      } else {
        localSetIsEditMode(false);
      }
    },
    [calendars],
  );

  const isAlreadyActive = useMemo(() => {
    return user.current_account === calendarAccount.provider;
  }, [user.current_account, calendarAccount]);

  const activateEnable = useMemo(() => {
    return !!(selected.length <= MAX_ENABLED && defaultId && selected.includes(defaultId));
  }, [selected, defaultId]);

  const [activating, setActivating] = useState(false);
  const performActivate = useCallback(async () => {
    if (selected.length <= MAX_ENABLED && defaultId && selected.includes(defaultId)) {
      // OK to proceed
    } else {
      return;
    }
    setActivating(true);
    try {
      await setDefaultCalendarV2UserV2CalendarsDefaultPut({
        enabled_calendars: selected.map((cid) => ({
          account_id: calendarAccount.account_name,
          provider: calendarAccount.provider,
          calendar_id: cid,
        })),
        default: {
          account_id: calendarAccount.account_name,
          provider: calendarAccount.provider,
          calendar_id: defaultId,
        },
        move_events: migrate,
      });
      toast("Activated");
      localSetIsEditMode(false);
      refreshUser();
      performLoadCalendars();
    } catch (e) {
      //
    } finally {
      setActivating(false);
    }
  }, [selected, calendarAccount, migrate, defaultId, refreshUser, performLoadCalendars]);

  return (
    <PageContext.Provider
      value={{
        calendarAccount,
        loadingCalendars,
        calendars,
        calendarMapping,
        defaultId,
        setDefaultId,
        isEditMode,
        setIsEditMode,
        selected,
        toggleSelectId,
        migrate,
        setMigrate,
        activateEnable,
        activating,
        performActivate,
        isAlreadyActive,
      }}
    >
      {children}
    </PageContext.Provider>
  );
}

function IsDefaultTag() {
  return (
    <div className="flex flex-row items-center rounded-md bg-green-700 py-0.5 pl-0.5 pr-2 text-xs text-white">
      <CalendarPlus2 height={12} className="stroke-white" />
      <span className="text-[10px]">Default</span>
    </div>
  );
}

function ActionButtonItem({
  disabled,
  onClick,
  children,
  icon,
}: PropsWithChildren<{ disabled?: boolean; onClick?: () => void; icon?: ReactNode }>) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="flex flex-row items-center justify-center gap-1 py-3 text-center text-sm disabled:opacity-40"
    >
      <div className="flex w-8 flex-shrink-0 flex-row justify-end text-right">{icon}</div>
      {children}
      <div className="w-8 flex-shrink-0"></div>
    </button>
  );
}

function CalendarItem({
  calendar,
  onClick,
  isSelected,
}: {
  calendar: CalendarInfoResponse;
  onClick: Dispatch<string>;
  isSelected: boolean;
}) {
  return (
    <SettingItem
      icon={<CalendarDot color={calendar.color!} />}
      label={calendar.name}
      suffix={<SwitchOn checked={isSelected} onChange={() => onClick(calendar.id)} />}
    />
  );
}

function CalendarDot({ color }: { color: string }) {
  return (
    <div className="flex h-4 w-4 flex-col items-center justify-center">
      <i className="h-2 w-2 rounded-full" style={{ backgroundColor: color }} />
    </div>
  );
}

function ReadonlyCalendarListGroup() {
  const { calendars, loadingCalendars } = usePageContext();
  const titleNode = <p>All calendars</p>;
  const suffixNode = <div>You can select multiple calendars</div>;

  if (!calendars.length) {
    if (loadingCalendars) {
      return (
        <SettingGroup title={titleNode} suffix={suffixNode}>
          <CommonLoading />
        </SettingGroup>
      );
    }
    return (
      <SettingGroup title={titleNode}>
        <div className="py-8 text-center">Empty</div>
      </SettingGroup>
    );
  }
  return (
    <SettingGroup title={titleNode} suffix={suffixNode}>
      {calendars.map((cal) => (
        <SettingItem
          key={cal.id}
          icon={<CalendarDot color={cal.color!} />}
          label={
            cal.is_default ? (
              <div className="flex flex-row items-center gap-2">
                <span>{cal.name}</span>
                {cal.is_default ? <IsDefaultTag /> : null}
              </div>
            ) : (
              <p className={cal.is_enabled ? "" : "opacity-40"}>{cal.name}</p>
            )
          }
          suffix={<SwitchOn checked={!!cal.is_enabled} disabled={true} />}
        />
      ))}
    </SettingGroup>
  );
}

function EditableCalendarListGroup() {
  const { selected, toggleSelectId, calendars } = usePageContext();
  return (
    <SettingGroup title="Select enabled calendars" suffix={<div>Explain about multiple calendars</div>}>
      {calendars.map((cal) => (
        <CalendarItem isSelected={selected.includes(cal.id)} onClick={toggleSelectId} key={cal.id} calendar={cal} />
      ))}
    </SettingGroup>
  );
}

function DefaultCalendarGroup() {
  const { selected, setDefaultId, defaultId, calendarMapping } = usePageContext();
  const selectCalendars = useMemo(() => {
    return selected.map((i) => calendarMapping[i]).filter(Boolean);
  }, [calendarMapping, selected]);

  return (
    <SettingGroup title="Choose Default Calendar" suffix="Explain about default calendar">
      {selectCalendars.length ? (
        <>
          {selectCalendars.map((c) => (
            <SettingItem
              icon={<CalendarDot color={c.color!} />}
              onClick={() => setDefaultId(c.id)}
              key={c.id}
              label={c.name}
              suffix={defaultId && defaultId === c.id ? <Check height={16} className="stroke-green-600" /> : null}
            />
          ))}
        </>
      ) : (
        <div className="py-8 text-center text-slate-400">Select enabled calendars first</div>
      )}
    </SettingGroup>
  );
}

function MigrateCheckGroup() {
  const { user } = useConfidenceUserContext();
  const { migrate, setMigrate } = usePageContext();
  if (user.current_account !== CalendarProviderName["self_hosted"]) return null;
  return (
    <SettingGroup
      suffix={
        <div className="flex flex-row items-start gap-2">
          <DolaIcon className="w-6 flex-shrink-0" />
          <span>
            Dola Calendar is your current active calendar. Please note that you will no longer be able to access entries
            in Dola Calendar once you switch to a 3rd party calendar. To migrate existing entries, please select
            "Migrate Existing Entries"
          </span>
        </div>
      }
    >
      <SettingItem
        label="Migrate existing calendar entries"
        suffix={<SwitchOn loading={false} checked={migrate} onChange={setMigrate} />}
      />
    </SettingGroup>
  );
}

function DisconnectActionGroup() {
  const { refresh: refreshUser } = useConfidenceUserContext();
  const { calendarAccount } = usePageContext();
  const go = useNavigate();
  const [disconnecting, setDisconnecting] = useState(false);
  const onDisconnect = useCallback(async () => {
    if (window.confirm("Are you sure to disconnect")) {
      try {
        setDisconnecting(true);
        await unbindCalendarAccountUserAccountsProviderDelete({
          provider: calendarAccount.provider,
        });
        toast("Disconnect");
        refreshUser();
        go(-1);
      } catch (e) {
        toast("Failed to disconnect");
      } finally {
        setDisconnecting(false);
      }
    }
  }, [calendarAccount, refreshUser, go]);

  return (
    <SettingGroup>
      <ActionButtonItem
        disabled={disconnecting}
        icon={
          disconnecting ? (
            <LoaderCircle height={16} className="animate-spin stroke-slate-400" />
          ) : (
            <Link2Off height={16} className="stroke-red-500" />
          )
        }
        onClick={onDisconnect}
      >
        <span className="text-red-500">Disconnect</span>
      </ActionButtonItem>
    </SettingGroup>
  );
}

function ActivateActionGroup() {
  const { activateEnable, activating, performActivate, isAlreadyActive } = usePageContext();

  const buttonDisabled = useMemo(() => {
    if (!activateEnable) return true;
    return activating;
  }, [activateEnable, activating]);
  return (
    <SettingGroup>
      <ActionButtonItem
        disabled={buttonDisabled}
        onClick={performActivate}
        icon={activating ? <LoaderCircle height={16} className="animate-spin stroke-slate-400" /> : null}
      >
        <span className="text-blue-600">{isAlreadyActive ? "Save" : "Activate"}</span>
      </ActionButtonItem>
    </SettingGroup>
  );
}

function ProviderInfoGroup() {
  const { user } = useConfidenceUserContext();
  const { calendarAccount } = usePageContext();
  const { t: text } = useTranslation("main", { keyPrefix: "CalendarProviderName" });
  const isActive = user.current_account === calendarAccount.provider;
  const statusIcon = isActive ? (
    <CircleDot height={16} className="fill-green-50 stroke-green-700" />
  ) : (
    <CircleDotDashed height={16} className="fill-slate-50 stroke-slate-400" />
  );

  return (
    <SettingGroup
      suffix={
        <div className="flex flex-row items-center gap-1">
          {statusIcon} {text(calendarAccount.provider)}{" "}
          {isActive ? "is your active calendar." : "is not your active calendar. You can activate it in this page."}
        </div>
      }
    >
      <div className="flex flex-row items-center gap-4 p-4">
        {createElement(
          calendarAccount.provider === CalendarProviderName["google"] ? GoogleCalendarIcon : AppleCalendarIcon,
          {
            className: "w-8",
          },
        )}
        <div className="flex-1">
          <div className="text-base">{text(calendarAccount.provider)}</div>
          <div className="text-xs text-slate-400">{calendarAccount.account_name}</div>
        </div>
        <div className="flex-shrink-0">{statusIcon}</div>
      </div>
    </SettingGroup>
  );
}

function ContentWithConfidentUser() {
  const { isEditMode } = usePageContext();

  return (
    <SettingGroups>
      <ProviderInfoGroup />
      {isEditMode ? (
        <>
          <EditableCalendarListGroup />
          <DefaultCalendarGroup />
          <MigrateCheckGroup />
          <ActivateActionGroup />
        </>
      ) : (
        <>
          <ReadonlyCalendarListGroup />
          <DisconnectActionGroup />
        </>
      )}
    </SettingGroups>
  );
}

function HeaderButton({ className, ...rest }: ButtonHTMLAttributes<HTMLButtonElement>) {
  return <button className={cn(className, "text-xs text-blue-600 disabled:opacity-40")} {...rest} />;
}

function PageHeaderRightAction() {
  const { isEditMode, setIsEditMode, loadingCalendars, activating, isAlreadyActive } = usePageContext();
  if (loadingCalendars) return null;
  if (!isEditMode)
    return <HeaderButton onClick={() => setIsEditMode(true)}>{isAlreadyActive ? "Edit" : "Activate"}</HeaderButton>;
  return (
    <HeaderButton disabled={activating} onClick={() => setIsEditMode(false)}>
      Cancel
    </HeaderButton>
  );
}

const VALID_PROVIDERS = [CalendarProviderName["icloud"], CalendarProviderName["google"]];

export default function SwitchThirdPartyCalendar() {
  const { t: text } = useTranslation("main", { keyPrefix: "CalendarProviderName" });
  const { provider } = useParams() as { provider: string };

  if (!VALID_PROVIDERS.includes(provider as CalendarProviderName)) {
    return (
      <SettingSubpage header="Error">
        <SettingGroups>
          <SettingGroup>
            <div className="flex flex-col items-center gap-4 py-20">
              <TriangleAlert width={40} height={40} className="stroke-red-700" />
              <div>Not supported provider: {provider}</div>
            </div>
          </SettingGroup>
        </SettingGroups>
      </SettingSubpage>
    );
  }

  return (
    <PossibleUserRender
      loading={
        <SettingSubpage header={<p>{text(provider)}</p>}>
          <SettingGroups>
            <SettingGroup>
              <CommonLoading />
            </SettingGroup>
          </SettingGroups>
        </SettingSubpage>
      }
      user={
        <PageContextProvider provider={provider as CalendarProviderName}>
          <SettingSubpage
            headerLeft={
              <HeaderLeftButton viewId="settings-calendars" to="/settings/calendars">
                Calendars
              </HeaderLeftButton>
            }
            headerRight={<PageHeaderRightAction />}
            header={<p style={defineViewId(`setting-calendar-${provider}`)}>{text(provider)}</p>}
          >
            <ContentWithConfidentUser />
          </SettingSubpage>
        </PageContextProvider>
      }
    />
  );
}
