import * as Switch from "@radix-ui/react-switch";
import { useThrottleFn } from "ahooks";
import { CircleX, LoaderCircle, Search, SquareArrowOutUpRight } from "lucide-react";
import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { IconArrowLeft, IconArrowRight } from "@/app/components/icons";
import { cn, defineViewId } from "@/lib/utils";

export function SwitchOn({
  loading,
  checked,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  loading?: boolean;
  checked: boolean;
  onChange?: (checked: boolean) => void;
}) {
  return (
    <Switch.Root
      disabled={loading || disabled}
      checked={checked}
      onCheckedChange={onChange}
      className="group h-[20px] w-[30px] rounded-full bg-slate-300 disabled:cursor-not-allowed disabled:opacity-40 aria-checked:bg-blue-600"
      id="time-mode-24-hour"
    >
      <Switch.Thumb className="flex h-[16px] w-[16px] translate-x-[2px] flex-col items-center justify-center overflow-hidden rounded-full bg-white transition-transform duration-100 ease-in-out group-aria-checked:translate-x-[12px]">
        {loading ? <LoaderCircle className="h-3 w-3 animate-spin stroke-slate-400" /> : null}
      </Switch.Thumb>
    </Switch.Root>
  );
}

export function SettingItem({
  icon,
  label,
  suffix,
  href,
  description,
  external,
  onClick,
}: {
  label: React.ReactNode;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  suffix?: React.ReactNode;
  href?: string;
  external?: boolean;
  onClick?: () => void;
}) {
  const suffixIcon = external ? (
    <SquareArrowOutUpRight className="w-3 stroke-slate-400" />
  ) : href ? (
    <IconArrowRight className="w-2 stroke-slate-400" />
  ) : null;

  const rowInternal = (
    <div className="flex flex-1 flex-row items-center justify-between border-b border-slate-200 py-3.5 pr-4 leading-tight group-last:border-transparent">
      <div className="flex flex-col gap-0.5">
        <div className="text-sm">{label}</div>
        {description ? <div className="text-xs text-slate-400">{description}</div> : null}
      </div>
      <div className="flex flex-row items-center justify-end gap-2">
        <div className="flex flex-row items-center text-xs text-slate-500">{suffix}</div>
        {suffixIcon}
      </div>
    </div>
  );

  const iconNode = icon ? (
    <div className="flex flex-col items-center justify-center border-b border-transparent">{icon}</div>
  ) : null;

  const wrapperStyles =
    "group flex flex-row items-center gap-2 bg-white hover:bg-slate-50 pl-4 transition-colors duration-100 active:bg-slate-50";

  if (href) {
    if (external) {
      return (
        <a onClick={onClick} target="_blank" href={href} className={wrapperStyles}>
          {iconNode}
          {rowInternal}
        </a>
      );
    }
    return (
      <NavLink
        onClick={onClick}
        unstable_viewTransition
        to={href}
        className={({ isTransitioning }) => cn(wrapperStyles, isTransitioning ? "transitioning" : "")}
      >
        {iconNode}
        {rowInternal}
      </NavLink>
    );
  }
  return (
    <div onClick={onClick} className={wrapperStyles}>
      {iconNode}
      {rowInternal}
    </div>
  );
}

export function SettingGroups({ children }: PropsWithChildren) {
  return <div className="flex flex-col gap-8 px-4">{children}</div>;
}

export function SettingGroup({
  children,
  title,
  suffix,
}: PropsWithChildren<{ title?: React.ReactNode; suffix?: React.ReactNode }>) {
  const contentNode = <div className="flex flex-col overflow-hidden rounded-lg bg-white text-xs">{children}</div>;
  if (!title && !suffix) return contentNode;
  return (
    <div className="flex flex-col gap-2">
      {title ? <div className="px-4 text-xs uppercase text-slate-800">{title}</div> : null}
      {contentNode}
      {suffix ? <div className="px-4 text-xs text-slate-500">{suffix}</div> : null}
    </div>
  );
}

export function HeaderLeftButton({ to, children, viewId }: PropsWithChildren<{ to: string; viewId?: string }>) {
  return (
    <Link
      unstable_viewTransition
      to={to}
      className="flex flex-row items-center gap-2 rounded-md p-1.5 text-xs text-slate-400 hover:bg-blue-50 active:bg-blue-100"
    >
      <IconArrowLeft className="w-2 stroke-blue-600" />
      <p style={viewId ? defineViewId(viewId) : {}} className="text-blue-600">
        {children}
      </p>
    </Link>
  );
}

const THRESHOLD = 10;
export function SettingSubpageHeader({
  children,
  dynamicShadow,
  extra,
  left,
  right,
}: PropsWithChildren<{ dynamicShadow?: boolean; extra?: ReactNode; left?: ReactNode; right?: ReactNode }>) {
  const ref = useRef<HTMLDivElement | null>(null);
  const [showShadow, setShowShadow] = useState(false);
  const { run: checkShouldShowShadow } = useThrottleFn(
    () => {
      setShowShadow(document.documentElement.scrollTop >= THRESHOLD);
    },
    {
      wait: 200,
    },
  );

  useEffect(() => {
    if (dynamicShadow) {
      checkShouldShowShadow();
      document.addEventListener("scroll", checkShouldShowShadow);
      return () => {
        document.removeEventListener("scroll", checkShouldShowShadow);
      };
    } else {
      setShowShadow(false);
    }
  }, [dynamicShadow, checkShouldShowShadow]);
  return (
    <div
      ref={ref}
      className={cn(
        "sticky top-0 z-20 grid grid-cols-[1fr_auto_1fr] justify-stretch gap-x-2 gap-y-4 self-center bg-[var(--background-color)] px-4 py-4 transition-shadow duration-100",
        showShadow ? "shadow-md" : "",
      )}
    >
      <div className="col-span-1 flex flex-row justify-start self-center">
        {left ? (
          left
        ) : (
          <HeaderLeftButton to="/settings" viewId="setting-main-title">
            Settings
          </HeaderLeftButton>
        )}
      </div>
      <div className="col-span-1 self-center text-center">{children}</div>
      <div className="col-span-1 flex flex-row justify-end self-center">{right}</div>
      {extra ? <div className="col-span-3">{extra}</div> : null}
    </div>
  );
}

export function SettingSubpage({
  children,
  header,
  headerExtra,
  headerLeft,
  headerRight,
}: PropsWithChildren<{ header: ReactNode; headerExtra?: ReactNode; headerLeft?: ReactNode; headerRight?: ReactNode }>) {
  return (
    <div className="min-h-[100dvh] w-full pb-8">
      <SettingSubpageHeader left={headerLeft} right={headerRight} extra={headerExtra} dynamicShadow>
        {header}
      </SettingSubpageHeader>
      {children}
    </div>
  );
}

export function SearchInput({ value, onChange }: { value: string; onChange: (v: string) => void }) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const onClear = useCallback(() => {
    onChange("");
    if (inputRef.current) inputRef.current.focus();
  }, [onChange]);
  return (
    <div className="relative w-full">
      <input
        ref={inputRef}
        className="h-8 w-full rounded-md border border-slate-200 bg-white px-2 leading-8 outline-none transition-colors duration-100 hover:border-slate-300 focus:outline-blue-400 active:border-slate-300"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      {value ? (
        <button onClick={onClear} className="absolute right-2 top-0 flex h-8 flex-row items-center justify-center px-1">
          <CircleX className="w-4 stroke-blue-400" />
        </button>
      ) : (
        <div className="absolute right-2 top-0 flex h-8 flex-row items-center justify-center px-1">
          <Search className="w-4 stroke-slate-400" />
        </div>
      )}
    </div>
  );
}
