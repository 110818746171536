import { useEffect, useState } from "react";

interface JSBridge {
  getUserToken(): Promise<string>;
  closePage(): Promise<void>;
  vibrate(): Promise<void>;
}

async function waitForEvent(eventName: string): Promise<void> {
  return new Promise((resolve) => {
    document.addEventListener(eventName, () => {
      resolve();
    });
  });
}

export async function getJSBridge(): Promise<JSBridge | null> {
  if (typeof window === "undefined") {
    return null;
  }

  if (!("WebViewJavascriptBridge" in window)) {
    await waitForEvent("WebViewJavascriptBridgeReady");
  }

  if (!("OABridge" in window)) {
    throw new Error("OABridge not found");
  }

  return createJSBridgeProxy(window.OABridge);
}

function createJSBridgeProxy(oaBridge: any): JSBridge {
  return {
    getUserToken: async () => {
      return new Promise((resolve) => {
        oaBridge.getUserToken(null, resolve);
      });
    },

    closePage: async () => {
      return new Promise((resolve) => {
        oaBridge.closePage(null, resolve);
      });
    },

    vibrate: async () => {
      return new Promise((resolve) => {
        oaBridge.vibrate(null, resolve);
      });
    },
  };
}

export function useJSBridge() {
  const [jsBridge, setJSBridge] = useState<JSBridge | null>(null);

  useEffect(() => {
    (async () => {
      const bridge = await getJSBridge();
      if (bridge) {
        setJSBridge(bridge);
      }
    })();
  }, []);

  return jsBridge;
}
