import { Check, LoaderCircle } from "lucide-react";
import { useCallback, useMemo, useState } from "react";

import { useUpdateUserSettingsUserSettingsPut } from "@/generated";
import { Language, matchedLanguage, supportedLanguages } from "@/lib/i18n";
import { defineViewId } from "@/lib/utils";

import { PossibleUserRender, useConfidenceUserContext } from "../../context";
import { SearchInput, SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";

function LanguageItem({
  language,
  onChange,
  isPending,
  isCurrentActive,
}: {
  language: Language;
  onChange: (v: string) => Promise<void>;
  isPending: boolean;
  isCurrentActive: boolean;
}) {
  const onClickChange = useCallback(async () => {
    onChange(language.locale);
  }, [onChange, language.locale]);
  return (
    <SettingItem
      onClick={onClickChange}
      label={<p style={defineViewId(`setting-language-${language.locale}`)}>{language.localLabel}</p>}
      description={language.englishLabel}
      suffix={
        isPending ? (
          <LoaderCircle className="w-4 animate-spin stroke-slate-400" />
        ) : isCurrentActive ? (
          <Check className="w-5 stroke-green-600" />
        ) : null
      }
    />
  );
}

function CurrentActive({
  localeString,
  localeDefine,
}: {
  localeString: string | undefined;
  localeDefine: Language | null;
}) {
  if (!localeString) return null;
  if (localeDefine) {
    return (
      <SettingGroup title="Current">
        <SettingItem
          suffix={<Check className="w-5 stroke-green-600" />}
          label={localeDefine.localLabel}
          description={localeDefine.englishLabel}
        />
      </SettingGroup>
    );
  }
  return (
    <SettingGroup title="Current">
      <SettingItem suffix={<Check className="w-5 stroke-green-600" />} label={localeString} />
    </SettingGroup>
  );
}

const availableLanguages = supportedLanguages.filter((l) => l.dir !== "rtl");
function LanguageListWithAction({ keyword }: { keyword: string }) {
  const { setUser, user } = useConfidenceUserContext();
  const currentActive = useMemo(() => {
    if (!user.settings.language) return null;
    return matchedLanguage(user.settings.language, availableLanguages);
  }, [user.settings.language]);

  const displayLanguages = useMemo(() => {
    const word = keyword.trim().toLowerCase();
    if (!word) return availableLanguages;
    return availableLanguages.filter((l) => {
      const candidate = `${l.lang} ${l.englishLabel} ${l.localLabel}`.toLowerCase();
      return candidate.includes(word);
    });
  }, [keyword]);

  const { isPending, mutateAsync } = useUpdateUserSettingsUserSettingsPut({});
  const [pendingValue, setPendingValue] = useState<string>();
  // Handle Cancel
  const onChange = useCallback(
    async (next: string) => {
      if (isPending) return;
      setPendingValue(next);
      const settings = await mutateAsync({ data: { language: next } });
      setUser((p) => ({ ...p, settings }));
    },
    [setUser, mutateAsync, isPending],
  );

  return (
    <>
      <CurrentActive localeString={user.settings.language} localeDefine={currentActive} />
      <SettingGroup title="Available Languages">
        {displayLanguages.map((langDefine) => (
          <LanguageItem
            isCurrentActive={!!(currentActive && currentActive.locale === langDefine.locale)}
            isPending={isPending && pendingValue === langDefine.locale}
            onChange={onChange}
            language={langDefine}
            key={langDefine.locale}
          />
        ))}
      </SettingGroup>
    </>
  );
}

export default function LanguageSettingPage() {
  const [keyword, setKeyword] = useState("");
  return (
    <SettingSubpage
      headerExtra={<SearchInput value={keyword} onChange={setKeyword} />}
      header={<p className="font-semibold view-id-[setting-language]">Language</p>}
    >
      <SettingGroups>
        <PossibleUserRender
          loading={
            <SettingGroup>
              <div className="flex flex-col items-center justify-center py-10">
                <LoaderCircle className="w-20 animate-spin stroke-slate-400" />
              </div>
            </SettingGroup>
          }
          user={<LanguageListWithAction keyword={keyword} />}
        />
      </SettingGroups>
    </SettingSubpage>
  );
}
