import { useWebSocket } from "ahooks";
import { ReadyState } from "ahooks/lib/useWebSocket";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { cn } from "@/lib/utils";

import { HeaderLeftButton, SettingSubpage } from "../settings/components/settings";

const SOCKET_URL = `https://ws.orionarm.ai/app/dola/chat/ws`;

type RawMessageType = { [key: string]: any };

type MessageType = {
  uuid: string;
  type: "sent" | "received";
  data: any;
};

function reducer(prev: MessageType[], payload: MessageType) {
  return [...prev, payload];
}

export default function ChatPage() {
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const [messages, addMessage] = useReducer(reducer, [] as MessageType[]);
  const [inputText, setInputText] = useState("");
  const inputTextRef = useRef(inputText);
  useEffect(() => {
    inputTextRef.current = inputText;
  }, [inputText]);
  const { sendMessage, latestMessage, readyState } = useWebSocket(SOCKET_URL);

  useEffect(() => {
    if (latestMessage) {
      try {
        const data = JSON.parse(latestMessage.data) as RawMessageType;
        addMessage({
          type: "received",
          uuid: data.req_id,
          data: data,
        });
        const timer = window.setTimeout(() => {
          bottomRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }, 500);
        return () => window.clearTimeout(timer);
      } catch (e) {
        //
      }
    }
  }, [latestMessage]);

  const performInit = useRef(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      sendMessage(
        JSON.stringify({
          type: "handshake",
          handshake: {
            token,
          },
        }),
      );
    }
  });

  useEffect(() => {
    console.log("readyState change");
    console.log(readyState);
    if (readyState === ReadyState.Open) {
      performInit.current();
    }
  }, [readyState]);

  const onSubmit = useCallback(() => {
    const messagePayload = {
      type: "send_message",
      send_message: { chat_id: "test", type: "text", text: { content: inputTextRef.current } },
    };
    sendMessage(JSON.stringify(messagePayload));
    addMessage({
      type: "sent",
      uuid: uuidv4(),
      data: messagePayload,
    });
    setInputText("");
  }, [sendMessage]);

  const onFormSubmit = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  return (
    <SettingSubpage
      headerLeft={<HeaderLeftButton to="/">Home</HeaderLeftButton>}
      header={<div className="font-semibold">Chat</div>}
    >
      <div className="flex min-h-[100dvh] flex-col gap-4 px-4 pb-20">
        {messages.map((msg) => (
          <div
            className={cn(
              "overflow-auto rounded-lg border p-2 text-xs",
              msg.type === "sent" ? "border-blue-200 bg-blue-50" : "border-slate-300 bg-white",
            )}
            key={msg.uuid}
          >
            <pre className="select-all">{JSON.stringify(msg.data, null, 2)}</pre>
          </div>
        ))}
        <div ref={bottomRef} className="h-1 w-full text-center">
          .
        </div>
      </div>
      <div className="fixed bottom-0 z-20 w-full border-t border-slate-200 bg-[var(--background-color)] p-4 pb-[env(safe-area-inset-bottom)]">
        <form onSubmit={onFormSubmit} className="flex flex-row items-center gap-2 pb-4">
          <input
            className="h-10 flex-1 rounded-md border border-slate-200 px-2 outline-none"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="跟 Dola 聊五毛钱的"
          />
          <button
            className="h-10 flex-shrink-0 rounded-md border border-transparent bg-blue-200 px-4 text-xs text-slate-600 transition-all duration-100 active:bg-blue-600 active:text-white disabled:opacity-30"
            disabled={inputText.trim().length === 0}
            onClick={onSubmit}
          >
            Send
          </button>
        </form>
      </div>
    </SettingSubpage>
  );
}
