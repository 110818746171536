import { CircleDot, LoaderCircle } from "lucide-react";

export function ActiveTag() {
  return (
    <div className="flex flex-row items-center gap-1">
      <CircleDot height={16} className="fill-green-50 stroke-green-700" />
      <span className="text-xs text-green-700">Active</span>
    </div>
  );
}

export function CommonLoading() {
  return (
    <div className="flex flex-col items-center py-8">
      <LoaderCircle height={20} className="animate-spin stroke-slate-400" />
    </div>
  );
}
