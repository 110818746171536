import { useEffect, useState } from "react";

import { waitUntil } from "@/lib/utils";

import { useJSBridge } from ".";

export default function AccessTokenProvider({ children }: { children: React.ReactNode }) {
  const [authFailed, setAuthFailed] = useState(false);
  const [accessToken, setAccessToken] = useState(localStorage.getItem("access_token"));

  const jsBridge = useJSBridge();
  useEffect(() => {
    (async () => {
      if (!jsBridge) {
        return;
      }
      const token = await waitUntil(jsBridge.getUserToken(), 4000);
      if (token && token !== accessToken) {
        localStorage.setItem("access_token", token);
        setAccessToken(token);
        setAuthFailed(false);
      } else {
        setAuthFailed(true);
      }
    })();
  }, [jsBridge, accessToken]);

  if (accessToken) return children;

  // TODO improve styles on these two
  if (authFailed) {
    return <div>Failed</div>;
  }

  return <div className="text-secondary-grey grid h-[100svh] place-content-center">Signing in...</div>;
}
