import { useRequest } from "ahooks";
import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { UserInfoResponse, getUserInfoUserInfoGet } from "@/generated";

interface UserContext<T> {
  user: T;
  setUser: Dispatch<SetStateAction<T>>;
  loading: boolean;
  refresh: () => Promise<UserInfoResponse>;
}

type PossibleUserContextType = UserContext<UserInfoResponse | undefined>;
const PossibleUserContext = createContext({} as PossibleUserContextType);

export function usePossibleUserContext() {
  return useContext(PossibleUserContext);
}

export function PossibleUserContextProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<UserInfoResponse | undefined>();
  const { data, loading, refreshAsync } = useRequest(() => getUserInfoUserInfoGet());

  useEffect(() => {
    setUser(data);
  }, [data]);

  return (
    <PossibleUserContext.Provider value={{ user, setUser, loading, refresh: refreshAsync }}>
      {children}
    </PossibleUserContext.Provider>
  );
}

type ConfidenceUserContextType = UserContext<UserInfoResponse>;
const ConfidenceUserContext = createContext({} as ConfidenceUserContextType);

export function useConfidenceUserContext() {
  return useContext(ConfidenceUserContext);
}

export function ConfidenceUserContextProvider({
  children,
  value,
}: PropsWithChildren<{ value: ConfidenceUserContextType }>) {
  return <ConfidenceUserContext.Provider value={value}>{children}</ConfidenceUserContext.Provider>;
}

type RenderProps = {
  loading?: ReactNode | (() => ReactNode);
  user?: ReactNode | ((u: UserInfoResponse) => ReactNode);
  anonymous?: ReactNode | (() => ReactNode);
};

export function PossibleUserRender(props: RenderProps) {
  const { loading, user, setUser, refresh } = usePossibleUserContext();

  const setUserWithConfidence = useCallback<ConfidenceUserContextType["setUser"]>(
    (p) => {
      setUser((prev) => {
        if (!prev) return undefined;
        if (typeof p === "function") {
          return p(prev);
        }
        return {
          ...prev,
          ...p,
        };
      });
    },
    [setUser],
  );

  if (user) {
    return (
      <ConfidenceUserContextProvider value={{ user, setUser: setUserWithConfidence, loading, refresh }}>
        {typeof props.user === "function" ? props.user(user) : (props.user ?? null)}
      </ConfidenceUserContextProvider>
    );
  }
  if (loading) {
    if (typeof props.loading === "function") {
      return props.loading();
    }
    return props.loading ?? null;
  }
  if (typeof props.anonymous === "function") {
    return props.anonymous();
  }
  return props.anonymous ?? null;
}
