import { Link } from "react-router-dom";

const links = [
  {
    path: "/settings",
    label: "Settings",
  },
  {
    path: "/events",
    label: "Events",
  },
  {
    path: "/chat",
    label: "Chat",
  },
];

export default function Home() {
  return (
    <div className="flex flex-col gap-4">
      {links.map((link) => (
        <Link className="text-blue-500 hover:underline" key={link.path} to={link.path}>
          {link.label}
        </Link>
      ))}
    </div>
  );
}
