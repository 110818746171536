import { CircleHelp, Cookie, HeartHandshake } from "lucide-react";

import { InstagramIcon, TwitterIcon } from "@/app/components/icons";

import { SettingGroup, SettingGroups, SettingItem, SettingSubpage } from "../components/settings";

export default function AboutDolaPage() {
  return (
    <SettingSubpage header={<p className="font-semibold view-id-[setting-about]">About Dola</p>}>
      <SettingGroups>
        <SettingGroup>
          <SettingItem
            icon={<Cookie className="w-4 fill-yellow-100 stroke-yellow-600" />}
            label="Privacy Policy"
            href="https://heydola.com/privacy"
            external
          />
          <SettingItem
            icon={<HeartHandshake className="w-4 fill-red-100 stroke-red-600" />}
            label="Terms of Service"
            href="https://heydola.com/tos"
            external
          />
          <SettingItem
            icon={<CircleHelp className="w-4 fill-blue-100 stroke-blue-600" />}
            label="FAQ"
            href="https://www.instagram.com/heydola.ai/"
            external
          />
          <SettingItem
            icon={<TwitterIcon className="w-3 stroke-black" />}
            label="Follow us on X"
            href="https://x.com/heydola_ai"
            external
          />
          <SettingItem
            icon={<InstagramIcon className="w-4 stroke-black" />}
            label="Follow us on Instagram"
            href="https://www.instagram.com/heydola.ai/"
            external
          />
        </SettingGroup>
      </SettingGroups>
    </SettingSubpage>
  );
}
